import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CSectTitle,
  CSectTitle03,
  CGeneralCard,
  CGeneralCard02,
  NavOiwai,
  CFaqList,
  CCard,
  CCard02,
  CSimpleAccordionList,
  CBtnList,
  CSectMedia,
  LContact03,
} from '../../components/_index';
// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const [modalFlag, setModalFlag] = useState(false);
  const openModal = (e: any) => {
    e.stopPropagation();
    setModalFlag(!modalFlag);
  };
  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        type="ja"
        data={{
          title: {
            main: 'お祝い',
            sub: <>CELEBRATION</>,
          },
          imgList: [
            {
              img: {
                src: '/assets/images/oiwai/kv.png',
              },
              imgSp: {
                src: '/assets/images/oiwai/kv__sp.png',
              },
            },
          ],
        }}
      ></CJumbotron>
      <div className="l_sect03 u_bgGray">
        <LWrap>
          <p className="u_tac_pc u_fs16">
            お子様との喜びの日、お子様の衣裳・着付と記念写真をセットにしたご会食プランをご用意しました。
            <br />
            かわいい記念撮影のあとは、ご家族そろってお子様の成長を願う慶びの宴をホテル自慢のレストランでお楽しみください。
          </p>
        </LWrap>
      </div>
      <section className="u_pt50 u_mbLarge">
        <LWrap>
          <NavOiwai exClass="u_mb60" />
          <div className="u_mb90">
            <CSectTitle03 title="安産祈願" exClass="u_mb40" />
            <CGeneralCard
              exClass="sp_full"
              data={[
                {
                  img: {
                    src: '/assets/images/oiwai/img_oiwai.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/oiwai/img_oiwai__sp.png',
                    alt: '',
                  },
                  link: {
                    href: '/oiwai/anzankigan/',
                  },
                  title: '安産祈願 特別ランチ',
                  text: (
                    <>
                      安産の象徴とされている犬（戌）にあやかり、安産を願う日として古くから信仰されている「戌の日」。
                      <br />
                      安産、子授け、成長を祈り、安産祈願
                      特別ランチプランを3つのレストランでご用意いたしました。
                      <br />
                      ノンカフェインのルイボスティーとご一緒にお楽しみください。
                    </>
                  ),
                },
              ]}
            />
            <CCard02
              exClass="u_mb0"
              data={[
                {
                  img: {
                    src: '/assets/images/oiwai/img_oiwai02.png',
                    alt: '',
                  },
                  title: <>マタニティプレート</>,
                  link: {
                    href: '/restaurants/plan/6c6wbc3xrgu/',
                  },
                },
                {
                  img: {
                    src: '/assets/images/oiwai/img_oiwai03.png',
                    alt: '',
                  },
                  title: <>子宝あんぱん</>,
                  link: {
                    href: 'https://www.tablecheck.com/shops/royalparkhotel-tokyo-iki/reserve?menu_lists[]=641d243c6b87d715e4153f4d&num_people=1',
                    blank: true,
                  },
                },
              ]}
            />
          </div>
          <div className="u_mb90">
            <CSectTitle03 title="お宮参り・お食い初め" exClass="u_mb40" />
            <CGeneralCard02
              col={2}
              data={[
                {
                  img: {
                    src: '/assets/images/oiwai/img_oiwai04.png',
                    alt: '',
                  },
                  link: {
                    href: '/oiwai/omiyamairi/',
                  },
                  title: 'お宮参り',
                  text: (
                    <>
                      お子様の誕生と健やかな成長を願う慶祝のひとときをおもてなしいたします。
                      <br />
                      水天宮でのお宮参りの後は、記念撮影とレストランの個室でお食事をお楽しみください。
                      お子様もご家族も安心してゆったりとおくつろぎいただけます。
                    </>
                  ),
                },
                {
                  img: {
                    src: '/assets/images/oiwai/img_oiwai05.png',
                    alt: '',
                  },
                  link: {
                    href: '/oiwai/okuizome/',
                  },
                  title: 'お食い初め',
                  text: (
                    <>
                      お子様が「一生食べることに困らないように」と願いを込めて、つつがなく成長するお子様のお祝いお食い初めは、日本伝統の大切な儀式。
                      <br />
                      お食い初め膳と特別コース料理がセットになった個室プランで、心を込めた料理とおもてなしでお迎えいたします。
                    </>
                  ),
                },
              ]}
            />
          </div>
          <div className="u_mb90">
            <CSectTitle03 title="七五三" exClass="u_mb40" />
            <CGeneralCard
              exClass="sp_full"
              data={[
                {
                  img: {
                    src: '/assets/images/oiwai/img_oiwai06.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/oiwai/img_oiwai06__sp.png',
                    alt: '',
                  },
                  link: {
                    href: '/oiwai/shichigosan/',
                  },
                  title: '七五三プラン',
                  text: (
                    <>
                      お子様との喜びの日、お子様の衣裳・着付と記念写真をセットにしたご会食プランをご用意いたしました。
                      <br />
                      記念撮影のあとは、ご家族そろってお子様の成長を願う慶びの宴をホテル自慢のレストランでお楽しみください。
                    </>
                  ),
                },
              ]}
            />
          </div>
          <div className="u_mb90">
            <CSectTitle03 title="お祝い" exClass="u_mb40" />
            <CGeneralCard
              exClass="u_mb80 sp_full"
              data={[
                {
                  img: {
                    src: '/assets/images/oiwai/img_oiwai07.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/oiwai/img_oiwai07__sp.png',
                    alt: '',
                  },
                  link: {
                    href: '/oiwai/oiwai_plan/',
                  },
                  title: 'お祝いプラン',
                  text: (
                    <>
                      お子様の初節句や成人式、ご家族のお誕生日、ご長寿のお祝いなど、人生の節目を祝う大切な日を、
                      <br />
                      個室でゆっくりとお過ごしいただけるプラン。
                      <br />
                      お食事とともにご家族の大切な思い出づくりにぜひご利用ください。
                    </>
                  ),
                },
              ]}
            />
            <div className="u_centerring" style={{ maxWidth: '780px' }}>
              <CGeneralCard
                data={[
                  {
                    img: {
                      src: '/assets/images/oiwai/img_oiwai08.png',
                      alt: '',
                    },
                    link: {
                      href: '/about/suitengu/',
                    },
                    title: '水天宮のご案内',
                    text: (
                      <>
                        東京の水天宮は、江戸時代から安産・子授けの神様として信仰を集めてきました。
                        <br />
                        当ホテルに隣接する水天宮をご紹介いたします。
                      </>
                    ),
                  },
                ]}
              />
            </div>
          </div>
          <div className="u_mb90">
            <CSectTitle03
              title={
                <>
                  お子様連れのお客様
                  <br />
                  よくあるご質問
                </>
              }
            />
            <CFaqList
              exClass="u_mb0"
              data={[
                {
                  q: '子供と利用する時に便利な設備やサービスはありますか?',
                  a: (
                    <>
                      レストランでは、お子様椅子・クーハンを無料でご用意しております。
                      <br />
                      そのほか、下記をご利用いただけます。
                      <br />
                      授乳室、ベビーベッド：1F ロビー
                      フロントカウンター横（ご利用の際はスタッフにお申し付けください）
                      <br />
                      折りたたみシート：1F バリアフリートイレ内
                      <br />
                      おむつ替えベッド：B1F、2F、4F 女性用化粧室内
                    </>
                  ),
                },
                {
                  q: '駐車場はありますか?',
                  a: (
                    <>
                      <p>
                        ご利用料金やプランなどに合わせたご優待をご用意しております。
                        <br />
                        詳細は<a href="/about/access/#car">こちら</a>
                        をご確認ください。
                      </p>
                    </>
                  ),
                },
                {
                  q: 'ベビーカーのまま利用できるレストランはありますか？',
                  a: (
                    <>
                      <p>
                        B1F メインバー
                        ロイヤルスコッツ以外の全レストランにてご利用いただけます。
                      </p>
                    </>
                  ),
                },
              ]}
            />
            <CBtnList
              exClass="u_mt40"
              data={[
                {
                  label: 'よくあるご質問',
                  link: {
                    href: '/faq/child/',
                  },
                  color: 'bgWhite03',
                },
              ]}
            />
          </div>
          <CSectMedia
            data={[
              {
                img: {
                  src: '/assets/images/oiwai/img_oiwai09.png',
                  alt: '',
                },
                imgSp: {
                  src: '/assets/images/oiwai/img_oiwai09__sp.png',
                  alt: '',
                },
                link: {
                  href: '/anniversary/',
                },
                title: <>記念日・プロポーズ</>,
                text: (
                  <>
                    一生の思い出に残る一日を。
                    <br />
                    バルーンデコレーションや、ケーキ、花束でサプライズはいかがですか。大切な一日を彩るプランやメニューをご用意しております。
                  </>
                ),
              },
            ]}
          />
        </LWrap>
      </section>
      <CBreadCrumb
        data={{
          parent: [],
          current: {
            label: frontmatter?.title,
          },
        }}
      />
      <LContact03
        title={'ご予約・お問合せ'}
        btn={{
          btn: {
            label: 'お問合せフォーム',
            link: {
              href: 'https://contact.royalparkhotels.co.jp/rph/contact?_ifbs-rph_contact_form=s1_Step1',
              blank: true,
            },
          },
        }}
        tel={{
          number: '03-5641-3600',
          note: 'レストラン予約（受付時間 9:00~19:00）',
        }}
        linkList={[
          {
            label: 'よくあるご質問',
            link: {
              href: '/faq/',
            },
          },
          {
            label: '食物アレルギーをお持ちの方へ',
            link: {
              href: '/restaurants/allergy/',
            },
          },
        ]}
      />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
